/** default link styling
 *  ------------------------------------------------------------------------------------------------
**/

a,
.anchor-button {
  color: var(--colour-secondary);
  text-decoration: none;
  font-weight: normal;
  transition:
    color var(--time-transition) var(--ease-default),
    background var(--time-transition) var(--ease-default),
    border-color var(--time-transition) var(--ease-default);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:focus {
    color: var(--colour-highlight);
  }

  &:active {
    /* ... */
  }

  &:visited {
    /* ... */
  }

  &:link {
    /* highlights links on iOS, basically works like the :hover selector for mobile devices. */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }
}

.anchor-button {
  background: transparent;
  border: 0;
  display: inline;
  padding: 0;
  cursor: pointer;
  outline: 0;
}
