/** CTA Buttons
 *  ------------------------------------------------------------------------------------------------
**/

.CTA {
  text-align: center;

  & .CTA__a {
    text-align: center;
    display: inline-block;
    padding: var(--bsu-s) var(--bsu-l);
    vertical-align: top;
    background: transparent;
    color: var(--colour-primary);
    border-radius: 0;
    font-family: var(--ff-body);
    font-weight: normal;
    border: 1px solid var(--colour-primary);
    outline: 0;
    cursor: pointer;
    transition:
      color var(--time-transition) ease-in-out,
      border var(--time-transition) ease-in-out,
      background var(--time-transition) ease-in-out;

    &:hover,
    &:focus {
      border-color: var(--colour-highlight);
    }
  }
}

.CTA--align-right {
  text-align: right;
}

.CTA--align-left {
  text-align: left;
}
