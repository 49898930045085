/** .PaddedImage
 *  ------------------------------------------------------------------------------------------------
 *  making images take the correct space even when unloaded
**/

.PaddedImage {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  /* max-height is 25em until the screen is bigger than 25 em high */
  max-height: 25em;

  & img {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    transform: translateY(-50%) translateX(-50%);

    @supports (object-fit: contain) {
      top: 0;
      left: 0;
      transform: none;
      max-height: none;
      max-width: none;
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  &.js--wants-intersection {
    /* background-color: currentColor; */

    & .PaddedImage__padder {
      background: var(--colour-background-body);
      opacity: 0;
      transition: opacity var(--time-transition) var(--ease-default);

      /* forcing ratio */
      /* padding-bottom: 66.666666666%; */
    }
  }
}

@media (--edge-to-edge-ends) {

  .PaddedImage.js--wants-intersection .PaddedImage__padder {
    /* coloured background is quite nice edge-to)edge */
    opacity: 0;
  }
}

@media screen and (min-height: 25em) {

  .PaddedImage {
    /* on smaller screens, use more height */
    max-height: 85vh;
  }
}

@media (--nav-break) and (min-height: 25em) {

  .PaddedImage {
    /* max height based on 10vh of padding at top, 8vh at bottom and then space for footer text. */
    max-height: 74vh;
  }
}

.js .PaddedImage.js--wants-intersection img {
  opacity: 0;
  transition: opacity var(--time-transition) var(--ease-default);
}

.js .PaddedImage.js--has-intersected .js--has-loaded {
  opacity: 1;
}

.js .PaddedImage.js--has-loaded-image .PaddedImage__padder {
  opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
  /* if you have specifically opted out of animation we don't want these to just pop in */
  .js .PaddedImage.js--wants-intersection img {
    opacity: 1;
  }

  .js .PaddedImage.js--wants-intersected .PaddedImage__padder {
    opacity: 1;
  }
}

.InlineImage {
  /* when we are edge to edge we pad above by a little extra... */
  --flow-space: var(--bsu-l);
}

.InlineImage .Wrap {
  padding: 0;
}

/* when we are edge to edge we want images immediately after CTAs to collapse their top margin */
/* images that follow images on edge-to-edge should also be seamless */
.u-flows > .InlineImage + .InlineImage,
.u-flows > .CTA + .InlineImage {
  --flow-space: 0;
}


@media (--edge-to-edge-ends) {

  .u-flows > .InlineImage + .InlineImage,
  .u-flows > .CTA + .InlineImage,
  .InlineImage {
    --flow-space: var(--bsu);
  }

  .InlineImage .Wrap {
    /* padding: 0 var(--bsu); */
  }
}

/* if the ratio was forced we want the image to cover the available space where possible */
/* .PaddedImage--forced-ratio {
  overflow: hidden;

  & img {
    top: 50%;
    left: 50%;
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    transform: translateY(-50%) translateX(-50%);

    @supports (object-fit: cover) {
      top: 0;
      left: 0;
      transform: none;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
} */

.PaddedImage--forced-ratio--contain {

  & img {

    @supports (object-fit: contain) {
      object-fit: contain;
    }
  }
}
