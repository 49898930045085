/** z-index assignment
 *  ------------------------------------------------------------------------------------------------
 *  ref: https://medium.com/@fat/mediums-css-is-actually-pretty-fucking-good-b8e2a6c78b06
 *
 *  a nice way to be able to have a super quick overview of how z-index is being used
 *  Indexes should all be assigned through this file so we can come back and tell at a glance where
 *  things exist in the z-index scale.
**/
:root {
  /** 0
   *  ----------------------------------------------------------------------------------------------
  **/
  --z-sections: 100;

  /** 1000
   *  ----------------------------------------------------------------------------------------------
  **/


  /** 2000
   *  ----------------------------------------------------------------------------------------------
  **/
  /* --z-loading-bar: 2000; */

  /** 3000
   *  ----------------------------------------------------------------------------------------------
  **/

  /** 4000
   *  ----------------------------------------------------------------------------------------------
  **/

  /** 5000
   *  ----------------------------------------------------------------------------------------------
  **/
  --z-fixed-header: 5000;
  --z-fixed-header-nav: 5100;
  --z-fixed-header-nav-close: 5200;

  /** 6000
   *  ----------------------------------------------------------------------------------------------
  **/

  /** 7000
   *  ----------------------------------------------------------------------------------------------
  **/
  --z-carousel-overlay: 7000;

  /** 8000
   *  ----------------------------------------------------------------------------------------------
  **/

  /** 9000
   *  ----------------------------------------------------------------------------------------------
  **/
  /* --z-notifications: 9000; */
}
