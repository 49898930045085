/** @font-face imports should happen here!
 *  ------------------------------------------------------------------------------------------------
**/


/** font families
 *  ------------------------------------------------------------------------------------------------
**/
:root {
  /*
  these are quite nice default families.
  but lately I prefer just using device fonts as the fallbacks...
  -
  --sans-serif: Helmet, Freesans, Tahoma, Geneva, sans-serif;
  --serif: Georgia, Cambria, 'Times New Roman', Times, serif;
  */
  --sans-serif: sans-serif;
  --serif: serif;


  /** Site font variables
  *  ------------------------------------------------------------------------------------------------
  **/
  --ff-jonathan: 'Helvetica', 'Arial', var(--sans-serif);

  /*
  the named font variables above aren't used directly
  instead we distinguish between the main (body) font and heading fonts
  */
  --ff-body: var(--ff-jonathan);
  --ff-heading: var(--ff-jonathan);
}
