/** Details that appear under work pages
 *  ------------------------------------------------------------------------------------------------
 *  images in the gallery have a max height of 74vh
 *  there is 10vh padding at the top
 *  and 8vh padding at the bottom
**/

.WorkDetails {
  padding: var(--bsu-l) 0 0;
  margin: 0 auto;
}

.WorkDetails__details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--bsu) 0;
  max-width: var(--layout-readable);
  max-width: calc(var(--layout-readable) + var(--bsu) * 2);
  margin: 0 auto;
}

.WorkDetails__title {
  font-size: var(--s1);
  line-height: 2;
}

.WorkDetails__title__title {
  font-style: italic;
}

.WorkDetails__count__prev,
.WorkDetails__count__next {
  display: inline-block;
  padding: 0 var(--bsu-s);
}

.WorkDetails__information-toggle {
  display: none;
}

@media (--nav-break) {

  .WorkDetails {
    padding: calc(var(--s3) * 2) calc(var(--s4) * 2) 0;
    padding: 5vh 7vw 0;
    max-width: none;
  }

  .WorkDetails__details {
    max-width: none;
    padding-right: 0;
    padding-left: var(--bsu-s);
    min-height: 3vh;
  }

  .WorkDetails__title {
    font-size: var(--s-2);
    line-height: 1.5;
    padding-right: var(--bsu);
  }

  .WorkDetails__title__title {
    font-style: normal;
    font-weight: bold;
  }

  .WorkDetails__information-toggle {
    display: block;
    order: -1;
    flex-grow: 1;
  }

  .WorkDetails__project-information {
    padding: 0 var(--bsu);

    & .Wrap {
      margin: 0;
    }
  }

  .js .js--active.WorkDetails__project-information {
    transform: translateY(0);
    opacity: 1;
    transition:
      opacity var(--time-transition) var(--time-transition-snap) var(--ease-default),
      transform var(--time-transition) var(--ease-default);
  }

  .js .js--inactive.WorkDetails__project-information {
    position: absolute;
    transform: translateY(calc(var(--bsu-l) * -1));
    opacity: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
}
