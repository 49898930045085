:root {
  /** primary colours
   *  ----------------------------------------------------------------------------------------------
  **/
  --colour-primary: #252525;
  --colour-secondary: #000000;
  --colour-highlight: #5d606b;

  /* --colour-highlight: #60b0ca; */

  --colour-subtle: #5d606b;
  --colour-subtler: #b1c1c7;
  --colour-subtlest: #e0e6e8;

  /** inverted colours
   *  ----------------------------------------------------------------------------------------------
  **/
  --colour-invert: #fafdfd;

  /** backgrounds
   *  ----------------------------------------------------------------------------------------------
  **/
  --colour-background-body: #ffffff;
  --colour-background-subtle: #fafdfd;

  /** utility
   *  ----------------------------------------------------------------------------------------------
  **/
  /* --colour-error: #993434; */
}
