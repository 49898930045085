/** Styling the WorkGallery
 *  ------------------------------------------------------------------------------------------------
**/

.WorkSection {
  padding: 0;
}

.WorkGallery {
  padding: 0 0 var(--bsu); /* bottom padding for scrollbar */
  margin-top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-color: var(--colour-subtle) var(--colour-subtlest);
  scroll-snap-type: x mandatory;
  scroll-behavior: auto;


  & > .InlineImage .Wrap,
  & .WorkGallery__ignore-me > .InlineImage .Wrap {
    padding: 0;
  }
}

.WorkGallery > * {
  flex-shrink: 0;
  width: 100%;
  scroll-snap-align: start;
  position: relative;
}

.WorkGallery > * + * {
  --flow-space: 0;

  margin-left: var(--bsu);
}


/** prev/next overlay
 *  ------------------------------------------------------------------------------------------------
**/
.WorkGallery__overlay__prev,
.WorkGallery__overlay__next {
  display: none;
}

/* .js .WorkGallery__overlay__prev,
.js .WorkGallery__overlay__next {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  margin: 0;
  z-index: var(--z-carousel-overlay);
  cursor: pointer;
  opacity: 0;
}

.js .WorkGallery__overlay__next {
  left: auto;
  right: 0;
} */


/** scrollbar
 *  ------------------------------------------------------------------------------------------------
**/
.WorkGallery::-webkit-scrollbar {
  height: 0.5rem;
}

.WorkGallery::-webkit-scrollbar-track {
  background-color: var(--colour-subtlest);
  border: 0 solid var(--colour-subtler);
}

.WorkGallery::-webkit-scrollbar-thumb {
  background-color: var(--colour-subtle);
}

@media (--nav-break) {

  .WorkGallery::-webkit-scrollbar-track {
    border-radius: 0.25rem;
  }

  .WorkGallery::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
  }
}

/* under 25em height we limit embed width - 44.44em maintains 25em height at 16:9 */
.WorkGallery__content--embed .Wrap {
  max-width: 44.44em;
}

@media screen and (min-height: 25em) {

  .WorkGallery__content--embed .Wrap {
    max-width: none;
  }
}

@media (--nav-break) and (min-height: 25em) {

  .js .WorkGallery {
    height: 74vh;
  }
}

/** Gallery with js
 *  ------------------------------------------------------------------------------------------------
**/

/* TODO hide scrollbars on small screens but still allow scroll (?) */
/* this requires intersection observers to change slide counter */
.js .WorkGallery {
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.js .WorkGallery.dragging {
  cursor: grabbing;
}

/* otherwise firefox drags image.... */
.js .WorkGallery img {
  pointer-events: none;
}

/* prevents embeds breaking drag */
.js .WorkGallery.dragging > * {
  pointer-events: none;
}

.js .WorkGallery::-webkit-scrollbar {
  height: 0;
  background: transparent;
}

/* then on larger screens no scrolling at all? */

/* .js .WorkGallery {
  padding-bottom: 0;
  overflow: hidden;
} */

.WorkGallery__content {
  transition:
    opacity var(--time-transition-slow) var(--ease-default),
    transform var(--time-transition-slow) var(--ease-default);
}


/** Displaying slides when JS is enabled
*  -----------------------------------------------------------------------------------------------
**/
.js .WorkGallery__content {
  opacity: 0.1;
}

.WorkGallery__content.js--carousel__slide--trans-in {
  opacity: 1;
}
