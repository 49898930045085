/** SiteHeader
 *  ------------------------------------------------------------------------------------------------
 *  SiteHeader uses our u-stacks utility class to sit the nav and logo next to each other when
 *  it's comfortable to do so.
**/

.SiteHeader {
  /* the wrap inside already has left/right padding on larger screens */
  padding: var(--bsu-l) 0 var(--bsu-l);
  background-color: var(--colour-background-body);
  color: var(--colour-primary);
  position: relative;
  z-index: var(--z-fixed-header);

  --stack-before: 40rem;

  & span {
    font-weight: bold;
  }

  & svg path {
    fill: var(--colour-primary);
  }
}


/** Header logo
 *  ------------------------------------------------------------------------------------------------
**/
.SiteHeader__logo {
  /* padding to bump down nav if required */
  /* padding-right: var(--bsu); */
  font-family: var(--ff-heading);
  font-size: var(--s-1);

  /* logo doesn't grow - give maximum space to the nav */
  /* this would cause the logo text to break if we didn't include the nowrap rule in the MQ below */
  flex-grow: 0;

  & .Logo > .SvgWrap,
  & .Logo > img {
    width: 200px; /* TODO: should we let CMS user decide this? */
    max-width: 100%;
  }
}

@media (--logo-stops-wrapping) {

  .SiteHeader__logo {
    white-space: nowrap;
  }
}

.SiteHeader__nav {
  /* allow nav to grow but not logo */
  display: block;
  flex-grow: 1;
  font-size: var(--s-1);

  & ul {
    justify-content: flex-end;

    --gap: var(--bsu);
  }

  & li {
    display: block;
  }

  & a,
  & .anchor-button {
    border-bottom: 2px solid var(--colour-background-body);
    display: inline-block;
    vertical-align: top;

    &:hover,
    &:focus {
      color: var(--colour-highlight);

      /* border-color: var(--colour-highlight); */
    }
  }

  & .nav__item--current > a,
  & .nav__item--current > .anchor-button {
    border-color: var(--colour-subtlest);
  }
}


/** column layout on wider screens
 *  ------------------------------------------------------------------------------------------------
**/

@media (--nav-break) {

  .SiteHeader {
    padding: 0;

    & .Wrap {
      padding: 0;
    }
  }

  .SiteHeader__logo {
    padding: 0 0 8vh;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .SiteHeader__nav {

    & li {
      flex-basis: 100%;
    }
  }
}
